<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="filterActive"
          color="primary"
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>

      <v-card>

        <v-card-title>Filter</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-autocomplete
            v-model="template_filter"
            :items="templates"
            item-text="name"
            item-value="id"
            label="Template"
            prepend-icon="mdi-book"
            return-object
          ></v-autocomplete>
          <v-autocomplete
            v-model="created_by_filter"
            :items="users"
            item-text="name"
            item-value="id"
            label="Erstellt von"
            prepend-icon="mdi-account"
            return-object
          ></v-autocomplete>
        </v-card-text>

        <v-card-text>
          <v-menu
            v-model="created_range_start_menu"
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="created_range_start_filter"
                label="Anfangsdatum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="created_range_start_filter"
              no-title
              @input="created_range_start_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-text>
          <v-menu
            v-model="created_range_end_menu"
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="created_range_end_filter"
                label="Enddatum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="created_range_end_filter"
              no-title
              @input="created_range_end_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <!--        <v-btn color="primary" @click="toggleSort">-->
        <!--          <v-icon>{{ sortIcons[sortCounter] }}</v-icon>-->
        <!--        </v-btn>-->

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetFilters">
            <v-icon left>mdi-filter-remove</v-icon>
            Filter löschen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="emitFilters"
          >
            <v-icon left>mdi-filter</v-icon>
            Filter anwenden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
export default {
  name: "MultiFilter",
  props: {
    filterActive: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    },
    created_by_filter: {
      type: String,
      default: ''
    },
    created_range_start_filter: {
      type: String,
      default: ''
    },
    created_range_end_filter: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      default: 1
    },
    template_filter: {
      type: String,
      default: ''
    }
  },
  created() {
    this.loadUsers()
  },
  computed: {
    created_range_start() {
      return this.created_range_start_filter ? this.created_range_start_filter + "T00:00:00.000Z" : ""
    },
    created_range_end() {
      return this.created_range_end_filter ? this.created_range_end_filter + "T23:59:59.999Z" : ""
    },
  },
  data: function () {
    return {
      sortIcons: [
        'mdi-sort-alphabetical-ascending',
        'mdi-sort-alphabetical-descending',
        'mdi-sort-calendar-ascending',
        'mdi-sort-calendar-descending',
        'mdi-sort-clock-ascending',
        'mdi-sort-clock-descending',
      ],
      sortCounter: 0,
      dialog: false,
      users: [],
      created_range_start_menu: false,
      created_range_end_menu: false,
      templates: [],
    }
  },
  mounted() {
      this.$templateController.getDocumentList()
        .then(res => {
          this.templates = res.data
        })
        .catch(res => {
          console.error(res)
          throw res
        })
  },
  methods: {
    loadUsers: async function () {
      let users = await this.$userController.getUserList()
      this.users = users.sort(
        (a, b) =>
          a.name.toLowerCase() === b.name.toLowerCase() ? 0 :
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
              -1
      )
    },
    toggleSort: function () {
      this.sortCounter++
      if (this.sortCounter >= this.sortIcons.length)
        this.sortCounter = 0
    },
    resetFilters: function () {
      this.dialog = false
      this.created_by_filter = ""
      this.template_filter = ""
      this.created_range_start_filter= ""
      this.created_range_end_filter= ""
      this.$emit('filtersReset')
    },
    emitFilters: function () {
      this.dialog = false
      let filters = {}
      if (this.created_range_start_filter) filters.created_range_start = this.created_range_start_filter
      if (this.created_range_end_filter) filters.created_range_end = this.created_range_end_filter
      if (this.created_by_filter) filters.created_by = this.created_by_filter.id
      if (this.template_filter) filters.template_filter = this.template_filter.id
      this.$emit('filtersApply', filters)
    },
    emitSearchText(value) {
      this.$emit('update:search-text', value)
    }
  }
}
</script>

<style scoped>

</style>
